import { createContext, useContext, useState } from 'react'
import {
  AssessmentAnswer,
  QuestionScoreParams,
  QuestionScrore,
} from '../services/types'
import _cloneDeep from 'lodash/cloneDeep'
import { CalculatorsApi } from '../services/api/calculators'
import { getStarRating } from '../services/projects'

export const defaultQuestionsContext = {
  nfa: 0,
  setNfa: async () => {},
  energyCoefficient: 0,
  setEnergyCoefficient: async () => {},
  answerIssues: [],
  setAnswerIssues: async () => {},
  isPlausible: () => true,
  isDisabled: false,
  setDisabled: () => {},
  checkQuestionScore: async () => {},
  getAnswerIssuesFromAssessmentAnswers: async () => {},
  sectionIssues: [],
  setSectionIssues: async () => {},
}

type QuestionsContextType = {
  nfa: number
  energyCoefficient: number
  setNfa: (val: number) => void
  setEnergyCoefficient: (val: number) => void
  answerIssues: QuestionScrore[]
  setAnswerIssues: (val: QuestionScrore[]) => void
  isPlausible: (answer?: {
    question_id: number
    sub_question_id: number | null
    item: number | string | null
  }) => boolean
  isDisabled: boolean
  setDisabled: (val: boolean) => void
  checkQuestionScore: (params: QuestionScoreParams) => void
  getAnswerIssuesFromAssessmentAnswers: (
    answers: AssessmentAnswer[],
    areaNet: number,
    energyCoefficient: number
  ) => void
  sectionIssues: string[]
  setSectionIssues: (val: string[]) => void
}

export const QuestionsContext = createContext<QuestionsContextType>(
  defaultQuestionsContext
)

export const useQuestionContext = () => useContext(QuestionsContext)

export const QuestionsProvider: React.FC = ({ children }) => {
  const [answerIssues, setAnswerIssues] = useState<QuestionScrore[]>([])
  const [nfa, setNfa] = useState<number>(0)
  const [energyCoefficient, setEnergyCoefficient] = useState<number>(0)
  const [isDisabled, setDisabled] = useState<boolean>(false)
  const [sectionIssues, setSectionIssues] = useState<string[]>([])

  const isPlausible = (answer?: {
    question_id: number
    sub_question_id: number | null
    item: number | string | null
  }) => {
    if (answerIssues.length === 0) return true
    return answer
      ? answerIssues.filter(
          (it) =>
            it.question_id === answer.question_id &&
            it.sub_question_id === answer.sub_question_id &&
            it.item === answer.item
        ).length === 0
      : answerIssues.length === 0
  }

  const checkQuestionScore = async (params: QuestionScoreParams) => {
    try {
      const nAnswerIssues = _cloneDeep(answerIssues)
      const idx = answerIssues.findIndex(
        (it) =>
          it.question_id === params.question_id &&
          it.item === params.item &&
          it.sub_question_id === params.sub_question_id
      )
      if (idx) {
        nAnswerIssues.splice(idx, 1)
      }

      const questionScore = await CalculatorsApi.questionScore(params)
      const answerIssue = {
        ...questionScore,
        question_id: params.question_id,
        sub_question_id: params.sub_question_id,
        item: params.item,
      } as QuestionScrore

      if (
        getStarRating(
          params.threeStarBenchmark,
          questionScore.carbon_cost_nfa
        ) === 1
      ) {
        nAnswerIssues.push(answerIssue)
      }
      setAnswerIssues([...nAnswerIssues])
    } catch (e) {}
  }

  const getAnswerIssuesFromAssessmentAnswers = async (
    answers: AssessmentAnswer[],
    areaNet: number,
    energyCoefficient: number
  ) => {
    try {
      if (answers && answers.length > 0) {
        let questionScores: QuestionScrore[] = await Promise.all(
          answers.map(
            async (it) =>
              await CalculatorsApi.questionScore({
                question_id: it.question_id,
                sub_question_id: it.sub_question_id,
                value: it.value,
                item: it.item,
                value_unit: it.value_unit,
                item_lifetime: it.lifetime || 0,
                nfa: areaNet,
                energy_coefficient: energyCoefficient,
              })
          )
        )

        if (questionScores.length > 0) {
          const answerHaveIssues = questionScores
            .map((item, index) => {
              return {
                ...item,
                ...answers[index],
                sub_question_id: answers[index].sub_question_id || null,
              }
            })
            .filter((it) => it.plausible === false)

          if (answerHaveIssues.length > 0) {
            setAnswerIssues(answerHaveIssues)
          }
        }
      }
    } catch (e) {}
  }

  return (
    <QuestionsContext.Provider
      value={{
        getAnswerIssuesFromAssessmentAnswers,
        checkQuestionScore,
        energyCoefficient,
        setEnergyCoefficient,
        setNfa,
        nfa,
        answerIssues,
        setAnswerIssues,
        isPlausible,
        isDisabled,
        setDisabled,
        sectionIssues,
        setSectionIssues,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  )
}
